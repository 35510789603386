.tox .tox-editor-header {
    z-index: 0 !important;
}

.tox .tox-statusbar__branding {
    display: none !important;
}

.basic-multi-select .select__multi-value{
    margin-left: 10px;
    margin-bottom: 10px;
}

.basic-multi-select .select__control.select__control--is-disabled, .basic-single .select__control.select__control--is-disabled {
    opacity: 0.4;
}

.accordion:last-child .accordion-header{
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
}

.accordion:not(:last-child) .accordion-header {
    border-bottom: 1px solid #d2d6dd;
}

.styledElement ul, .styledElement ol, .styledElement li {
    list-style: inherit !important;
    list-style-type: inherit !important;
    margin: inherit !important;
    padding: inherit !important;
}

.styledElement ol {
    list-style-type: decimal !important;
}

.styledElement table {
    border-top-width: 1px !important;
    border-right-width: 1px !important;
    border-bottom-width: 1px !important;
    border-left-width: 1px !important;
    border-color: grey !important;
}

.styledElement tr {
    border-bottom-width: 1px !important;
    border-color: grey !important;
}

.styledElement td {
    min-width: 20px;
    border-right-width: 1px !important;
    border-color: grey !important;
}

.styledElement p {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.dimensionsTargetsSynthesis {
    width: 100%;
    flex-direction: row !important;
    flex-wrap: wrap;
}

.dimensionsTargetsSynthesis .dimension-studies-child {
    flex: 1 0.5 auto;
    margin: 10px;
}

.dimensionsTargetsSynthesis .dimensions-header {
    padding: 0 10px;
}

.dimensionsTargetsSynthesis .dimensions-targets tr th {
    padding: 10px;
}